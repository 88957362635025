<template>
  <div class="card p-2 m-2 bg-card" v-for="(item, index) in items" :key="index">
    <div class="mb-2 first-line">
      <div class="thumbnail" />
      <div class="number" />
    </div>
    <p class="title" />
  </div>
</template>

<script setup>
const items = 5;
</script>

<style scoped>
.number {
  margin-bottom: 0;
  width: 205px;
  height: 140px;
  background-color: #ddd;
  animation: pulse-bg 1s infinite;
}

.title {
  background-color: #ddd;
  height: 27px;
  animation: pulse-bg 1s infinite;
}

.thumbnail {
  width: 100px;
  height: 155px;
  background-color: #ddd;
  animation: pulse-bg 1s infinite;
}

.first-line {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.bg-card {
  background-color: whitesmoke;
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }

  50% {
    background-color: #d0d0d0;
  }

  100% {
    background-color: #ddd;
  }
}
</style>